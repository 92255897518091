import {
  useFocusable,
  FocusContext,
  setFocus,
} from "@noriginmedia/norigin-spatial-navigation";
import FocusableChild from "../components/FocusableChild";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "../utils/Axios";
import QRCode from "react-qr-code";

export default function IntroPixelStreamingPage() {
  const navigate = useNavigate();
  const { id } = useParams();

  // Spatial navigation setup
  const { ref, focusKey } = useFocusable({
    focusKey: "pixel-stream-page",
    onEnterPress: () => {},
    onEnterRelease: () => {},
    onArrowPress: (e) => {},
    onFocus: () => {},
    onBlur: () => {},
  });

  const [tour, setTour] = useState(null);
  const [loading, setLoading] = useState(false);

  // Fetch data from your API
  useEffect(() => {
    const getTours = async () => {
      try {
        setLoading(true);
        const { data } = await axios.get(`/v3/hotel-form/form/pixel-streaming`);
        setTour(data.content[id]);
        setLoading(false);

        setFocus("pixel-intro-button");
      } catch (err) {
        console.error("Error fetching tours:", err);
        setLoading(false);
      }
    };
    getTours();
  }, [id]);

  if (loading) {
    return (
      <p className="w-full h-screen grid place-items-center text-5xl">
        Loading...
      </p>
    );
  }

  if (!tour) {
    return (
      <p className="w-full h-screen grid place-items-center text-5xl">
        Tour not found or unavailable.
      </p>
    );
  }

  return (
    <FocusContext.Provider value={focusKey}>
      <div
        ref={ref}
        className="relative w-full h-screen ml-24 flex items-center justify-start"
      >
        <p className="absolute left-6 top-8 text-2xl">NOMADVERSE</p>

        <div className="ml-32 flex flex-col w-min h-min items-start">
          <p className="w-[785px] mb-16 text-[36px] font-light text-left">
            {tour.description}
          </p>

          <FocusableChild
            borderRadius="36px"
            className="w-[340px] h-[73px]"
            focusKeyParam={"pixel-intro-button"}
            onEnter={() => {
              navigate(
                `/video-player/?videoHD=${tour.videoHD}&videoFHD=${
                  tour.videoFHD
                }&poster=${tour.image}&showQR=${
                  id === "casino" // only pass showQR=true if id is casino
                }`
              );
            }}
            onArrowPress={(e) => {
              if (e === "left") return true; // block left arrow
              return false;
            }}
          >
            <button className="w-full h-full text-center bg-primary-800 border border-white rounded-[36px] font-medium text-xl">
              Start Now
            </button>
          </FocusableChild>
        </div>

        {/* Tour Image */}
        <img
          src={tour.image}
          alt="tour"
          className="absolute right-[50px] h-[868px] -z-50"
        />

        {/* Render the QR code only if id === "casino" */}
        {id === "casino" && (
          <div className="absolute bottom-10 right-10">
            <QRCode
              value="https://betinity.com/"
              size={128}
              bgColor="#ffffff"
              fgColor="#000000"
              level="L"
            />
          </div>
        )}
      </div>
    </FocusContext.Provider>
  );
}
