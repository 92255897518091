import axios from "axios";

const api = axios.create({
	baseURL: "https://back.nomadverse.info",
});

api.interceptors.request.use(
	(config) => {
		const authToken = localStorage.getItem("auth");
		if (authToken) {
			config.headers.Authorization = `${authToken}`;
		}

		return config;
	},
	(error) => {
		return Promise.reject(error);
	},
);

api.interceptors.response.use(
	(response) => {
		return response;
	},
	(error) => {
		const status = error.response.status || null;

		if (status === 403) {
			localStorage.removeItem("auth");
			window.location.href = "/sign-in";
		}
		return Promise.reject(error);
	},
);

export default api;
